import { jsx } from "theme-ui";
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../ui-components/layout";
import { Box } from "rebass";

// import AlternatingImage from "../../components/alternating-image/alternating-image";
import FullScreenImage from "../../components/full-screen-image/full-screen-image";
import SEO from "../../components/seo/seo";
import Text from "../../components/text/text";
import Prefooter from "../../components/prefooter/prefooter";
import { DiscussionEmbed } from "disqus-react";
import MainContainer from "../../ui-components/container/container";

interface BlogProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
        githubLink: string;
      };
    };
    contentfulBlogPost: {
      updatedAt: any;
      id: number;
      metaTitle: string;
      metaDescription: {
        metaDescription: string;
      };
      tags: [];
      slug: string;
      socialMediaImage: {
        file: {
          url: string;
        };
      };
      language: [];
      section: [];
      prefooter: any;
    };
  };
}

export const blogQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      updatedAt
      id
      metaTitle
      metaDescription {
        metaDescription
      }
      socialMediaImage {
        file {
          url
        }
      }
      language
      slug
      tags
      section {
        ...FullScreenImageSection
        ...TextSection
      }
      prefooter {
        ...Prefooter
      }
    }
  }
`;

// TODO: add back in when they want it ...AlternatingImageSection

const BlogPage = ({
  data: {
    contentfulBlogPost: {
      metaTitle,
      metaDescription,
      socialMediaImage,
      slug,
      tags,
      language,
      section,
      prefooter
    }
  }
}: BlogProps) => {
  const disqusConfig = {
    shortname: "emiliemartinez-com",
    config: { identifier: slug }
  };

  const components = {
    // ContentfulAlternatingImageSection: AlternatingImage,
    ContentfulFullScreenImageSection: FullScreenImage,
    ContentfulTextSection: Text
  };

  // @ts-ignore
  const ComponentMatch = data => {
    // @ts-ignore
    const MatchingComponent = components[data.internal.type];
    return <MatchingComponent data={data} />;
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription.metaDescription}
        image={socialMediaImage.file.url}
        pathname={slug}
        lang={language}
      />
      <main>
        {section.map((component: any) => (
          <ComponentMatch
            {...component}
            key={component.heading || component.title}
          />
        ))}
        <MainContainer my={4} width="100%">
          {tags &&
            tags.map((tag: string) => (
              <Box
                key={tag}
                sx={{
                  display: "inline-block",
                  color: "white",
                  bg: "secondary",
                  px: 2,
                  py: 1,
                  borderRadius: 9999,
                  mr: 2
                }}
              >
                {tag}
              </Box>
            ))}
        </MainContainer>
        <MainContainer my={4} width="100%">
          <DiscussionEmbed className="discussionEmbed" {...disqusConfig} />
        </MainContainer>
        {prefooter && (
          <Prefooter data={prefooter} />
        )}
      </main>
    </Layout>
  );
};

export default BlogPage;
